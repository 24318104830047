import "./tool-variables.scss";

import "./tool-buttons.scss";

import "./tool-page.scss";
import "./tool-intro.scss";

import "@mod-kenjebankzaken/webdesigns/shared/tool-questions.scss";
import "@mod-kenjebankzaken/webdesigns/shared/tool-results-effectgroups.scss";
import "@mod-kenjebankzaken/webdesigns/shared/tool-results-sharebar.scss";
import "./tool-results-extras.scss";

import "./tool-popup.scss";
import "./tool-reminder.scss";

import "./tool-infobutton.scss";

//import * as dialogapi from 'dompack/api/dialog';
import * as dompack from "dompack";
import * as effecttool from "@mod-toolplatform/tools/effecttool";
import * as whintegration from "@mod-system/js/wh/integration";
import "@mod-kenjebankzaken/webdesigns/shared/wh.social.sharepage.es";

import * as toolhelpers from "@mod-kenjebankzaken/webdesigns/shared/effectgroups-as-accordeon.es";

import tippy from 'tippy.js';
// import tippy, {roundArrow} from 'tippy.js';
//import tippy, {followCursor} from 'tippy.js/headless';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/svg-arrow.css';




// window.setInterval(function() {console.log(document.activeElement); }, 500);
window.whint = whintegration; // debug



let dontcountfirstquestion = true;

let debugmode;
if (location.href.indexOf("debug") > -1)
  debugmode = true;


// Nodes
let toolnode;

let prefilllink = "";

let questions_skipped = 0;

function earlyInit()
{
  tippy.setDefaultProps(
      { arrow:  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 32 16"><path d="M16 0l16 16H0z"></path></svg>' });
}


function initToolExtras(node)
{
  console.info('INIT "Ik en geld quiz" tool extras');

  if (whintegration.config.dtapstage == "production")
    effecttool.setupGA("G-2KBLR1YEX5", "Ik en geld");
  else
    effecttool.setupGA("G-N144DR9KX3", "Ik en geld");

  //alert("100vh" + document.body.height + ", real height " + window.innerHeight);

  window.addEventListener("resize", updateRealVH);
  updateRealVH();


  if (debugmode)
    document.documentElement.classList.add("debug-tool");

  toolnode = node;

  resetProgressBar(); // FIXME: we don't receive the first question event??

  // Listen for the tool to be finished. When it shows the resultpage we go to page 1b (our custom resultpage) and enable the button to page 2
  node.addEventListener("toolplatform:progress", onToolPageSwitch);
  node.addEventListener("toolplatform:resultspage", onToolResultsPage);



  rewriteDOM(node);



  let copylinkbutton = document.querySelector(".customtool__copyprefilllink");
  if (copylinkbutton)
    copylinkbutton.addEventListener("click", doCopyPrefillLinkToClipboard);

  let restartbutton = document.querySelector(".customtool__restart");
  if (restartbutton)
    restartbutton.addEventListener("click", doRestart);

  moveInfoButtonsToQuestionHeader(); // handle the question info buttons
  // tippy('[data-tippy-content]'); // handle custom info buttons in the witty

  prefillAnswersFromURL();
}



function rewriteDOM(node)
{
  seperateQuestionHeadings();

  toolhelpers.morphEffectGroupsIntoAccordeon(node);
}


/*
function startTheTool()
{
  window.__toolcontroller._gotoFirstQuestion();
}
*/

function seperateQuestionHeadings()
{
  // .effecttool__question
}



function updateRealVH()
{
  document.documentElement.style.setProperty("--realvh", window.innerHeight + "px");
}



function resetProgressBar()
{
  let questions = document.querySelectorAll(".effecttool__question").length;
  setProgress(1, questions);
}

function doRestart()
{
  /*
  NOTE: this version without reload doesn't work correctly yet.. After the first question it jumps to the results.

  window.__toolcontroller.goToQuestionById(window.__toolcontroller.getQuestionIdByNr(0));
  resetProgressBar();
  toolnode.classList.remove("showtool");
  */

  window.addEventListener("beforeunload", () => window.scrollTo(0,0));
  location.reload();
}


// NOTE: ToolPlatform offers window.getToolPrefillURL, but it doersn't work for checkbox questions
function generateURLFromAnswers(answers)
{
  // answers
  //   .uid
  //   .value[]
  let panswers = [];
  for (let answer of answers)
  {
    // console.log("answer", answer);
    panswers.push(answer.uid + "=" + encodeURI( answer.value.join(",") ));
  }
  let query = panswers.join("&");

  let finalurl = location.origin + location.pathname + "?prefill&" + query;

  //console.log(finalurl);

  return finalurl;
}


function doCopyPrefillLinkToClipboard()
{
  navigator.clipboard.writeText(prefilllink);
}



function prefillAnswersFromURL()
{
  console.info("prefillAnswersFromURL");

  let urlParams = new URLSearchParams(window.location.search);

  if (!urlParams.has("prefill"))
    return;

  window.__toolcontroller._gotoFirstQuestion();

  for (const [key, detokenizedvalue] of urlParams.entries())
  {
    let values = detokenizedvalue.split(",");

    for (const value of values)
    {
        console.log(key, value);

        // For check name={guid}
        // For radiobutton name=answer-{guid}

        let node = document.querySelector(`[name^="${key}"][value="${value}"]`);
        if (node)
        {
          node.checked = true; // check the checkbox
          node.setAttribute("checked", true);
          // console.log("SELECTED", node);
          continue;
        }

        node = document.querySelector(`[name^="answer-${key}"][value="${value}"]`);
        if (node)
        {
          node.checked = true;
          node.setAttribute("checked", true);
          // console.log("SELECTED", node);
          continue;
        }

        //console.log("Node", node);
        console.log("Failed to find", key, "value", value);
    }
  }


  let breakin = 99;
  while(true) // do...while
  {
    let result = window.__toolcontroller.doNext();
    console.log(result);
    if (!result.success || result.page == "results")
      break;

    breakin--;
    if (breakin === 0)
    {
      console.error("FAIL");
      break;
    }
  }

  doStartTool();


  /*

  Selector to find the checkbuttons
  [name^="{questionguid}"][value="{answerguid}"]

  Checkbox
  <input type="checkbox" name="vg1RXa6bSAUVkstyaUlCAQ" value="Z-aWgErRYJi3JGZD3vwLvg" id="answer-Z-aWgErRYJi3JGZD3vwLvg" data-uid="Z-aWgErRYJi3JGZD3vwLvg" data-points="0" autocomplete="off">


  Selector to find the radiobuttons
  [name^="answer-{questionguid}"][value="{answerguid}"]

  Radiobutton
  <input type="radio" name="answer-SUY8GCyVcklX9shNbLojpg"
         value="gg4qgq2DLyGI5cJUYz0ufQ"
         id="answer-SUY8GCyVcklX9shNbLojpg-1" data-points="0" autocomplete="off">

  */
}



/////////////////////////////////////////////////////////////////////
//
//  Vragen pagina's


// Move the info in the DOM and attach Tippy so the info appears as tooltip
function moveInfoButtonsToQuestionHeader()
{
  let questionnodes = toolnode.querySelectorAll(".effecttool__question");

  for (let questionnode of questionnodes)
  {
    let moreinfo = questionnode.querySelector(".effecttool__moreinfo");
    if (!moreinfo)
      continue;

    let new_info = getInfoButton(); //moreinfo.innerHTML);

    // new_info.querySelector(".avtool__infobutton__popup").appendChild(moreinfo);
    tippy( new_info.querySelector("button")
         , { content: moreinfo.innerHTML
           , allowHTML: true
         //, hideOnClick: false // set to false for testing
           , trigger: 'mouseenter click'
           });
    moreinfo.parentNode.removeChild(moreinfo); // remove the original moreinfo node


    let questiontext_node = questionnode.querySelector(".effectool__questiontext");

/*
    let questiontext_h2 = questiontext_node.querySelector("h2");

    if (questiontext_h2)
      questiontext_h2.appendChild(new_info);
    else
      questiontext_node.appendChild(new_info);
*/
    questiontext_node.insertBefore(new_info, questiontext_node.firstChild);
  }
}

function getInfoButton()
{
  return <div class="avtool__infobutton">
           <button class="avtool__infobutton__button" tabindex="0"></button>
         </div>;
}

function onToolPageSwitch(evt)
{
  /*
  NOTES:
  - at this point .effecttool__page--visible is still the page from before .nextPage() was called
  - window.__toolcontroller contains the ToolController instance (we assume to only have one tool on the page)
  */

  console.log("toolplatform:progress", evt.detail);

  setProgress( evt.detail.answers.length + 1
               , evt.detail.numquestions - evt.detail.questionsleft + 1
               , evt.detail.numquestions);

  let nextpage = evt.detail.nextpage;
  if (!nextpage.question) // not a question page (probably the resultpage)
    return;
}


function setProgress(xthseen, current, total)
{
  // console.info("setProgress", current, total);

  let bulletstotal = total;
  if (dontcountfirstquestion)
  {
    xthseen--;
    if (xthseen == 0)
    {
      current = 0;
      total = 0;
    }
    else
    {
      if (xthseen == 1)
        questions_skipped = current - 1;

      current -= questions_skipped;
      total -= questions_skipped;
    }
  }

  /*
  console.info({ xthseen: xthseen
               , current: current

               , total:   total
               , questions_skipped: questions_skipped
               });
  */

  if (current < 1)
  {
    document.querySelector(".customtool__progress").style.visibility = "hidden";
    return;
  }
  else
  {
    document.querySelector(".customtool__progress").style.visibility = "visible";
    document.querySelector(".customtool__progress__number").innerText = xthseen;
  }

  let container = document.querySelector(".customtool__progress__pages");
  if (!container.firstChild)
  {
    for (let page = 1; page <= total; page++)
    {
      let page = document.createElement("div");
      page.classList.add("customtool__progress__page");
      container.appendChild(page);
    }
  }

  // Update active state
  let pagenodes = document.querySelectorAll(".customtool__progress__page");
  for (let page = 0; page < bulletstotal-1; page++)
  {
    console.log(page, pagenodes[page]);
    pagenodes[page].style.display = page > total ? "none" : "block";
    pagenodes[page].classList.toggle("active", page < current);
  }
}


/////////////////////////////////////////////////////////////////////
//
//  Resultaten pagina

function getScoreWrapper(points)
{
  if (points >= 30)
  {
    return <div class="kenjebankzaken-score">
             <div class="kenjebankzaken-score__symbol" aria-hidden="true">
               {/*<span class="far fa-smile-beam"></span>*/}
               <span class="fas fa-circle"></span>
               <span class="far fa-smile"></span>
             </div>
             <div class="kenjebankzaken-score__text">
               {points} van de <span class="kenjebankzaken-score__points">50</span> punten.<br />
               Goed gedaan!
             </div>
           </div>;
  }
  else if (points >= 16)
  {
    return <div class="kenjebankzaken-score">
             <div class="kenjebankzaken-score__symbol">
               <span class="fas fa-circle"></span>
               <span class="far fa-meh"></span>
             </div>
             <div class="kenjebankzaken-score__text">
               {points} van de <span class="kenjebankzaken-score__points">50</span> punten.<br />
             </div>
          </div>;
  }
  else
  {
    return <div class="kenjebankzaken-score">
             <div class="kenjebankzaken-score__symbol">
               <span class="fas fa-circle"></span>
               <span class="far fa-sad-tear"></span>
             </div>
             <div class="kenjebankzaken-score__text">
               {points} van de <span class="kenjebankzaken-score__points">50</span> punten.<br />
            </div>
           </div>;
  }
}

function onToolResultsPage(evt)
{
  console.group("onToolResultsPage");
  console.log("[p3dtool] Results page", evt.detail);


document.documentElement.classList.add("onresultspage");

  let resultsheader = document.querySelector(".effecttool__results__header");

  let scorewrapper = getScoreWrapper(evt.detail.points);
  resultsheader.appendChild(scorewrapper);


//  if (evt.detail.points)

/*
  let bdate_answer = results.answers.find(function(answer) { return answer.question.toLowerCase() == "geboortedatum"; });
  let prefilllink = generateURLFromAnswers(evt.detail.answers);
*/

console.log(evt.detail);

  //console.log("Answers", evt.detail.answers);

  let results = evt.detail;
  window.toolresult = results;

  // let results = getToolResults(); // gives back some more info than passed in evt.details (points and measuregroups) - NOT EXPOSED
  console.log("getToolResults", results);
  console.groupEnd();

 // morphEffectsIntoAccordeon();

  openFirstVisibleEffectGroup();

  // makePartOfEffectsGroupTitleBold();

  // updateEffectsCountText(evt);

  addOddEvenClassnamesToEffects();

  prefilllink = generateURLFromAnswers(evt.detail.answers);


  //if (whintegration.config.obj.mailresultstext)
  //  finalurl += "&" + encodeURI(whintegration.config.obj.mailresultstext);

  // Update mailto: URL in button
  let subject = "Ik en geld quiz";
  let body = whintegration.config.obj.mailresultstext + "\n\n" + prefilllink;
  let anchorbutton = document.querySelector(".customtool__mailprefilllink");
  anchorbutton.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
}

function openFirstVisibleEffectGroup()
{
  let effectgroups = document.querySelectorAll(".effecttool__group");

  idx = 0;
  for(let node of effectgroups)
  {
    if (node.hasAttribute("hidden"))
      continue;

    if (idx == 0) // first visible effectgroup ?
      node.setAttribute("open", "");

    idx++;
  }
}

function addOddEvenClassnamesToEffects()
{
  let effectgroups = toolnode.querySelectorAll(".effecttool__group");
  for (let group of effectgroups)
  {
    let effects = group.querySelectorAll(".effecttool__effect");

    let idx = 0;
    for(let effect of effects)
    {
      if (effect.hasAttribute("hidden"))
        continue;

      idx++;
      effect.classList.toggle("effecttool__effect__odd",  idx % 2 == 1);
      effect.classList.toggle("effecttool__effect__even", idx % 2 == 0);
    }
  }
}


earlyInit();

dompack.register(".effecttool--ikengeld", initToolExtras);
